const galleryList = [
  {
    id: 1,
    image: "catering.jpg",
    description: "",
  },
  {
    id: 2,
    image: "cafe.jpg",
    description: "",
  },
  {
    id: 3,
    image: "banquet.jpg",
    description: "",
  },
  {
    id: 4,
    image: "market.jpg",
    description: "",
  },
  {
    id: 5,
    image: "takeout.jpg",
    description: "",
  },
];

export default galleryList;
