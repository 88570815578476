import { useLayoutEffect } from "react";

const Admin = () => {
  useLayoutEffect(() => {
    window.location.href = "https://www.babushkawc.com/admin";
  }, []);

  return null;
};

export default Admin;
